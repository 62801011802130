


























































































































































































































































































































































import ModalOpenPreview from "@/components/modal/latestUpdate/ModalOpenPreview.vue";
import {
  BannerMediaType,
  BannerOpenType,
  BannerStatus,
  BannerStep,
  BannerUrlType,
  LoadingStatusType,
} from "@/enums/enums";
import { CreatelatestUpdateRequestModel } from "@/model/latestUpdate/latestUpdateModel";
import { Component, Vue, Watch } from "vue-property-decorator";
import { VueEditor } from "vue2-editor";
import { namespace } from "vuex-class";
import { FileModel } from "../../model/file/fileModel";

const StoreLatestUpdate = namespace("LatestUpdate");
@Component({ components: { VueEditor, ModalOpenPreview } })
export default class EditLatestUpdate extends Vue {
  private action: any = [
    { actionValue: BannerOpenType.Modal, text: "Open a Modal" },
    { actionValue: BannerOpenType.Link, text: "Open a URL" },
  ];
  private destination: any = [
    { actionValue: BannerUrlType.Peak, text: "Link PEAK Program" },
    { actionValue: BannerUrlType.Other, text: "Link อื่น ๆ" },
  ];
  private customToolbar: any = [
    [{ header: [2, 3, false] }],
    ["bold", "italic"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["blockquote", "link"],
  ];
  private actionValue = BannerOpenType.Modal;
  private step = BannerStep.StepThumbnail;
  private isShowOpenPreviewModal = false;
  private previewThThumbnail = "";
  private previewEnThumbnail = "";
  private previewImageCoverTH = "";
  private previewImageCoverEN = "";

  // form input internal information
  private code = "";
  private name = "";
  private description = "";
  private thThumbnail: any = null;
  private enThumbnail: any = null;
  private BannerOpenType = BannerOpenType;
  private BannerStep = BannerStep;
  private BannerMediaType = BannerMediaType;
  // form input open modal
  private isCheckButton = false;
  private previewLang = "";
  private openModalData = {
    headerTH: "",
    headerEN: "",
    mediaTH: BannerMediaType.Image,
    mediaEN: BannerMediaType.Image,
    youtubeURLTH: "",
    youtubeURLEN: "",
    imageTH: null,
    imageEN: null,
    contentTH: "",
    contentEN: "",
    buttonNameTH: "",
    buttonNameEN: "",
  };
  // form input rule
  private dateCurrent = new Date();
  private startDate = this.dateCurrent.setDate(this.dateCurrent.getDate() + 1);
  private endDate = this.dateCurrent.setDate(this.dateCurrent.getDate() + 1);
  private datefrom = new Date(this.startDate - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  private menufrom = false;
  private modalfrom = false;
  private menu2from = false;
  private dateto = new Date(this.endDate - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  private menuto = false;
  private modalto = false;
  private menu2to = false;

  private destinationValue = BannerUrlType.Peak;
  private destinationURL = "";
  private mediaType: any = { photo: BannerMediaType.Image, video: BannerMediaType.Link };

  @StoreLatestUpdate.Action
  private DoEditBanner!: (editlatestUpdateRequestModel: CreatelatestUpdateRequestModel) => void;
  @StoreLatestUpdate.Action
  private DoGetBannerDetail!: (code: string) => void;
  @StoreLatestUpdate.Getter
  private getBannerDetailLoadingStatus!: LoadingStatusType;
  @StoreLatestUpdate.Getter
  private getBannerDetail!: any | null;
  @StoreLatestUpdate.Getter
  private getEditBannerRequestLoadingStatus!: LoadingStatusType;

  @Watch("getEditBannerRequestLoadingStatus")
  getEditBannerRequestLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      alert("บันทึกสำเร็จ ข้อมูลที่แก้ไขจะถูกอัปเดตเวลา 24:00 น.");
      window.location.href = "/latestUpdate";
    }
  }

  @Watch("getBannerDetailLoadingStatus", { immediate: true })
  getBannerDetailLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      if (this.getBannerDetail) {
        this.code = this.getBannerDetail.code || "";
        this.name = this.getBannerDetail.name || "";
        this.description = this.getBannerDetail.description || "";
        this.actionValue = this.getBannerDetail.bannerType;
        this.previewThThumbnail = this.getBannerDetail.thumbnailFile.thumbnailThPath;
        this.previewEnThumbnail = this.getBannerDetail.thumbnailFile.thumbnailEnPath;
        const filterContentTH = this.getBannerDetail.contents.find((item: any) => item.language === "th");
        const filterButtonTH = this.getBannerDetail.buttons.find((item: any) => item.language === "th");
        const bannerDetailTH = { ...filterContentTH, ...filterButtonTH };
        const filterContentEN = this.getBannerDetail.contents.find((item: any) => item.language === "en");
        const filterButtonEN = this.getBannerDetail.buttons.find((item: any) => item.language === "en");
        const bannerDetailEN = { ...filterContentEN, ...filterButtonEN };

        if (this.getBannerDetail.bannerType === BannerOpenType.Link) {
          this.destinationValue = this.getBannerDetail.bannerLink && this.getBannerDetail.bannerLink.bannerLinkType;
          this.destinationURL = this.getBannerDetail.bannerLink ? this.getBannerDetail.bannerLink.url : "";
        } else if (this.getBannerDetail.bannerType === BannerOpenType.Modal) {
          this.destinationValue = BannerUrlType.Other;
          this.destinationURL = bannerDetailTH.url;
        }

        this.datefrom = new Date(this.getBannerDetail.startDatetime).toISOString().substr(0, 10);
        this.dateto = new Date(this.getBannerDetail.endDatetime).toISOString().substr(0, 10);
        if (this.getBannerDetail.bannerType === BannerOpenType.Modal) {
          this.openModalData.mediaTH = bannerDetailTH.mainMediaType;
          this.openModalData.mediaEN = bannerDetailEN.mainMediaType;
          if (bannerDetailTH.mainMediaType === BannerMediaType.Link) {
            this.openModalData.youtubeURLTH = bannerDetailTH.mainMediaUrl;
          }
          if (bannerDetailEN.mainMediaType === BannerMediaType.Link) {
            this.openModalData.youtubeURLEN = bannerDetailEN.mainMediaUrl;
          }
          this.previewImageCoverTH = bannerDetailTH.mainMediaUrl;
          this.previewImageCoverEN = bannerDetailEN.mainMediaUrl;
          this.openModalData.headerTH = bannerDetailTH.title;
          this.openModalData.headerEN = bannerDetailEN.title;
          this.openModalData.contentTH = bannerDetailTH.content;
          this.openModalData.contentEN = bannerDetailEN.content;
          this.openModalData.buttonNameTH = bannerDetailTH.text;
          this.openModalData.buttonNameEN = bannerDetailEN.text;
          if (this.getBannerDetail.buttons && this.getBannerDetail.buttons.length > 0) {
            this.isCheckButton = true;
          }
        }
      }
    }
  }
  created() {
    this.GetBannerDetail();
  }
  public GetBannerDetail() {
    const emtStr: any = this.$route.query.code;
    this.code = emtStr;
    this.DoGetBannerDetail(this.code);
  }
  public NextStep() {
    this.step += 1;
  }
  public BackStep() {
    if (this.step > BannerStep.StepThumbnail) {
      this.step -= 1;
    }
  }
  public ValidateInternalInformation() {
    const checkAndAlert = (condition: any, message: string) => {
      if (condition) {
        alert(message);
        this.step = BannerStep.StepThumbnail;
        return true;
      }
      return false;
    };
    if (
      checkAndAlert(!this.name, "กรุณากรอก Name ให้ถูกต้อง") ||
      checkAndAlert(this.name.length > 100, "ความยาว Name มากกว่า 100 ตัวอักษร") ||
      checkAndAlert(!this.description, "กรุณากรอก Description ให้ถูกต้อง") ||
      checkAndAlert(this.description.length > 100, "ความยาว Description มากกว่า 100 ตัวอักษร")
    ) {
      return false;
    }

    return true;
  }
  public ValidateOpenAction() {
    const checkAndAlert = (condition: any, message: string) => {
      if (condition) {
        alert(message);
        this.step = BannerStep.StepDetail;
        return true;
      }
      return false;
    };

    if (this.actionValue === BannerOpenType.Modal) {
      if (
        checkAndAlert(this.openModalData.headerTH === "", "กรุณากรอก Header ภาษาไทยให้ถูกต้อง") ||
        checkAndAlert(this.openModalData.headerTH.length > 60, "ความยาว Header ภาษาไทยเกิน 60 ตัวอักษร") ||
        checkAndAlert(this.openModalData.headerEN === "", "กรุณากรอก Header ภาษาอังกฤษให้ถูกต้อง") ||
        checkAndAlert(this.openModalData.headerEN.length > 60, "ความยาว Header ภาษาอังกฤษเกิน 60 ตัวอักษร") ||
        checkAndAlert(
          this.openModalData.mediaTH === BannerMediaType.Link && this.openModalData.youtubeURLTH === "",
          "กรุณาเพิ่มลิงค์ Youtube ภาษาไทย"
        ) ||
        checkAndAlert(
          this.openModalData.mediaEN === BannerMediaType.Link && this.openModalData.youtubeURLEN === "",
          "กรุณาเพิ่มลิงค์ Youtube ภาษาอังกฤษ"
        ) ||
        checkAndAlert(this.isCheckButton && this.openModalData.buttonNameTH === "", "กรุณากรอก Button Name ภาษาไทย") ||
        checkAndAlert(
          this.isCheckButton && this.openModalData.buttonNameTH.length > 16,
          "ความยาว Button Name ภาษาไทยเกิน 16 ตัวอักษร"
        ) ||
        checkAndAlert(
          this.isCheckButton && this.openModalData.buttonNameEN === "",
          "กรุณากรอก Button Name ภาษาอังกฤษ"
        ) ||
        checkAndAlert(
          this.isCheckButton && this.openModalData.buttonNameEN.length > 16,
          "ความยาว Button Name ภาษาอังกฤษเกิน 16 ตัวอักษร"
        ) ||
        checkAndAlert(this.isCheckButton && !this.destinationValue, "กรุณาเลือก Destination") ||
        checkAndAlert(this.isCheckButton && this.destinationURL === "", "กรุณากรอก Destination URL")
      ) {
        return false;
      }
    } else if (this.actionValue === BannerOpenType.Link) {
      if (
        checkAndAlert(!this.destinationValue, "กรุณาเลือก Destination") ||
        checkAndAlert(this.destinationURL === "", "กรุณากรอก Destination URL")
      ) {
        return false;
      }
    }

    return true;
  }
  public GetFileModel(file: any) {
    if (file.getChosenFile()) {
      const fileType = file.getChosenFile().type;
      return {
        rawString: file.originalImage.src,
        fileUrl: "",
        fileType: fileType,
      };
    } else {
      return null;
    }
  }
  public UpdateEmiValue() {
    if (this.destinationValue === BannerUrlType.Peak) {
      const emiRegex = /emi=([^&]*)/;
      this.destinationURL = this.destinationURL.replace(emiRegex, "emi={emi}");
    }
  }
  public ValidateRule() {
    const createAndSetDate = (dateString: string | Date) => {
      const date = new Date(dateString);
      date.setHours(0, 0, 0, 0);
      return date;
    };

    const today = createAndSetDate(new Date());
    const dateFrom = createAndSetDate(this.datefrom);
    const dateTo = createAndSetDate(this.dateto);
    if (
      dateFrom <= today &&
      this.datefrom !== new Date(this.getBannerDetail.startDatetime).toISOString().substr(0, 10)
    ) {
      alert("Start Date ต้องมากกว่าวันปัจจุบัน");
      return false;
    } else if (dateTo <= dateFrom) {
      alert("End Date ต้องมากกว่า Start Date");
      return false;
    }
    return true;
  }
  public Save() {
    if (this.ValidateInternalInformation() && this.ValidateOpenAction() && this.ValidateRule()) {
      const button: any[] = [];
      if (this.isCheckButton) {
        button.push({ text: this.openModalData.buttonNameTH, url: this.destinationURL, language: "th" });
        button.push({ text: this.openModalData.buttonNameEN, url: this.destinationURL, language: "en" });
      }
      const content: any[] = [];
      let thThumbnailFileModel: FileModel | null = this.thThumbnail;
      let enThumbnailFileModel: FileModel | null = this.enThumbnail;

      let imageFileThModel: FileModel | null = this.thThumbnail;
      let imageFileEnModel: FileModel | null = this.enThumbnail;

      const tmpConditionImageTH: any = this.openModalData.imageTH;
      const tmpConditionImageEN: any = this.openModalData.imageEN;
      if (this.thThumbnail.hasImage()) {
        thThumbnailFileModel = this.GetFileModel(this.thThumbnail);
      } else {
        thThumbnailFileModel = null;
      }
      if (this.enThumbnail.hasImage()) {
        enThumbnailFileModel = this.GetFileModel(this.enThumbnail);
      } else {
        enThumbnailFileModel = null;
      }
      if (tmpConditionImageTH.hasImage()) {
        imageFileThModel = this.GetFileModel(this.openModalData.imageTH);
      } else {
        imageFileThModel = null;
      }
      if (tmpConditionImageEN.hasImage()) {
        imageFileEnModel = this.GetFileModel(this.openModalData.imageEN);
      } else {
        imageFileEnModel = null;
      }
      if (this.BannerOpenType.Modal) {
        content.push({
          title: this.openModalData.headerTH,
          language: "th",
          mainMediaType: this.openModalData.mediaTH,
          mainMediaUrl: this.openModalData.youtubeURLTH,
          content: this.openModalData.contentTH,
        });
        content.push({
          title: this.openModalData.headerEN,
          language: "en",
          mainMediaType: this.openModalData.mediaEN,
          mainMediaUrl: this.openModalData.youtubeURLEN,
          content: this.openModalData.contentEN,
        });
      }
      let bannerLink = null;
      if (this.actionValue === BannerOpenType.Link) {
        bannerLink = {
          url: this.destinationURL,
          bannerLinkType: this.destinationValue,
        };
      }
      const input: CreatelatestUpdateRequestModel = {
        code: this.code.trim(),
        name: this.name,
        description: this.description,
        order: this.getBannerDetail.order,
        startDatetime: this.datefrom,
        endDatetime: this.dateto,
        bannerStatus: BannerStatus.Pending,
        ImageFileTh: imageFileThModel,
        ThumbnailFileTh: thThumbnailFileModel,
        ImageFileEn: imageFileEnModel,
        ThumbnailFileEn: enThumbnailFileModel,
        bannerLink: bannerLink,
        buttons: button,
        contents: content,
        bannerType: this.actionValue,
      };
      this.DoEditBanner(input);
    }
  }
  public ShowPreview(lang: string) {
    this.previewLang = lang;
    this.isShowOpenPreviewModal = true;
  }
}
